'use client';
import { mergeRefs } from '@chakra-ui/hooks/use-merge-refs';
import { isStylePropFn, css } from '@chakra-ui/styled-system';
import { assignAfter } from '@chakra-ui/utils/assign-after';
import { compact } from '@chakra-ui/utils/compact';
import { interopDefault } from '@chakra-ui/utils/interop-default';
import { runIfFn } from '@chakra-ui/utils/run-if-fn';
import { splitProps } from '@chakra-ui/utils/split-props';
import createStyled from '@emotion/styled';
import { forwardRef, createElement, Children, isValidElement, useMemo } from 'react';
import { mergeProps } from './merge-props.mjs';
import { shouldForwardProp } from './should-forward-prop.mjs';
import { useColorMode } from '../color-mode/color-mode-context.mjs';

const emotion_styled = interopDefault(createStyled);
const toCSSObject = ({ baseStyle }) => (props) => {
  const { theme, css: cssProp, __css, sx, ...restProps } = props;
  const isStyleProp = isStylePropFn(theme);
  const [styleProps] = splitProps(restProps, isStyleProp);
  const finalBaseStyle = runIfFn(baseStyle, props);
  const finalStyles = assignAfter(
    {},
    __css,
    finalBaseStyle,
    compact(styleProps),
    sx
  );
  const computedCSS = css(finalStyles)(props.theme);
  return cssProp ? [computedCSS, cssProp] : computedCSS;
};
function styled(component, options) {
  const { baseStyle, ...styledOptions } = options ?? {};
  if (!styledOptions.shouldForwardProp) {
    styledOptions.shouldForwardProp = shouldForwardProp;
  }
  const styleObject = toCSSObject({ baseStyle });
  const Component = emotion_styled(
    component,
    styledOptions
  )(styleObject);
  const chakraComponent = forwardRef(
    function ChakraComponent2(props, ref) {
      const { asChild, children, ...restProps } = props;
      const { colorMode, forced } = useColorMode();
      const dataTheme = forced ? colorMode : void 0;
      if (!asChild) {
        return createElement(
          Component,
          {
            ref,
            "data-theme": dataTheme,
            ...restProps
          },
          children
        );
      }
      const onlyChild = Children.only(props.children);
      if (isValidElement(onlyChild)) {
        const composedProps = mergeProps(restProps, onlyChild.props ?? {});
        const composedRef = ref ? mergeRefs(ref, onlyChild.ref) : onlyChild.ref;
        const styledElement = useMemo(
          () => emotion_styled(onlyChild.type, styledOptions)(styleObject),
          [onlyChild.type]
        );
        return createElement(styledElement, {
          ref: composedRef,
          "data-theme": dataTheme,
          ...composedProps
        });
      }
      return onlyChild;
    }
  );
  return chakraComponent;
}

export { styled, toCSSObject };
